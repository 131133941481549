
import { defineComponent } from 'vue';
import Breadcrumb from '@/views/new-design/layout/Breadcrumb.vue';
import {
  Actions as MemberActions,
  Getters as MemberGetters,
} from '@/store/enums/MemberEnums';
import { mapActions, mapGetters } from 'vuex';

import { acronym } from '@/utils/text';
import useBreakpoints from 'vue-next-breakpoints';
import { User } from '@/models/UserModel';

import { Picture as IconPicture } from '@element-plus/icons-vue';
import Chip from '@/components/Chip.vue';

const memberInit = {} as unknown as User;

export default defineComponent({
  components: {
    Breadcrumb,
    Chip,
    IconPicture,
  },
  data: () => ({
    loading: false,
    counterTimer: '',
    startMember: false,
    showWinner: false,
    memberData: memberInit,
    drawLotData: {} as any,
    loadingDetails: false,
    activeMenu: 'details',
  }),
  async mounted() {
    await this.getDetail();
  },
  computed: {
    ...mapGetters({
      member: MemberGetters.GET_MEMBER,
      errors: MemberGetters.GET_ACTION_ERRORS,
    }),
    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },

    breadcrumbs() {
      return [
        {
          to: '/members',
          text: 'Members',
          current: false,
        },
        {
          to: `/members/${this.$route.params.member_id}`,
          text: 'Member Details',
          current: true,
        },
      ];
    },

    address() {
      const addressLine =
        this.member && this.member?.address_line
          ? this.member.address_line
          : '';
      const city =
        this.member && this.member?.city ? `, ${this.member.city}` : '';
      const state =
        this.member && this.member?.state ? `, ${this.member.state}` : '';
      const postCode =
        this.member && this.member?.post_code
          ? ` ${this.member.post_code}`
          : '';
      const country =
        this.member && this.member?.country ? `, ${this.member.country}` : '';

      return `${addressLine}${city}${state}${postCode}${country}`;
    },
  },
  methods: {
    ...mapActions({
      fetchMember: MemberActions.FETCH_MEMBER,
    }),

    getStatus(member) {
      if (member?.subscription?.code === 'gold') {
        return {
          type: 'warning',
          text: 'Gold',
          value: true,
          color: 'text-warning',
        };
      }

      if (member?.subscription?.code === 'silver') {
        return {
          type: 'secondary',
          text: 'Silver',
        };
      }

      if (member?.subscription?.code === 'starter') {
        return {
          type: 'dark',
          text: 'Starter',
        };
      }

      return {
        type: 'light',
        text: 'None',
      };
    },

    async getDetail() {
      this.loading = true;

      await this.fetchMember({ id: this.$route.params.member_id }).then(() => {
        this.loading = false;
      });
    },

    onPageChanged() {
      console.log('test');
    },

    getAcronym(name = '') {
      return acronym(name);
    },
  },
  watch: {
    async member(values) {
      this.memberData = (await values) as User;
    },
  },
});
